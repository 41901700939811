.ReactModal__Overlay {
    background-color: inherit;
    opacity: 0;
    transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    background-color: rgba(14, 14, 14, 0.6);
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    background-color: inherit;
    opacity: 0;
}
