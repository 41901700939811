@import "./root", "./fonts", "./reset.css";

html, body{
    width: 100%;
}

body {
  margin: 0;
  font-family: "gilroy4", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-black);
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
}

.splide {

  .splide__track {
    height: 100%;
  }

  .splide__pagination {
    gap: 8px;
    position: absolute;
    bottom: -30px;
  }

  .splide__pagination__page {
    margin: 0;
    width: 6px;
    height: 6px;
  }

  .splide__pagination__page.is-active {
    background: #7797FB;
    transform: scale(1);
  }

  .splide__arrows {
    display: none;
  }
}

