:root {
    // colors

    --white: #FFF;
    --black: #000;
    --main-black: #42444D;
    --main-gray: #70747E;
    --second-gray: #7C7F8E;
    --green: #60BF81;
    --blue: #5D91FB;
    --violet: #7797FB;
    --red: #E0596E;

    --pl-black: #232323;
    --pl-violet: #7797FB;

    // background colors

    --bg-main-gray: #F9FAFE;
    --bg-second-gray: #F2F4FB;
    --bg-light-green: #31CB96;
    --bg-middle-green: #E2F8E4;
    --bg-dark-green: #60BF81;
    --bg-active-green: #F4FFFB;

    // borders

    --br-small: 8px;
    --br-main: 12px;
    --br-modal: 15px;

    //shadows

    --sh-main: 0 2.40278px 12.0139px rgba(20, 67, 114, 0.08);
    --sh-modal: 0 3px 15px rgba(20, 67, 114, 0.08);
}
