@font-face {
    font-family: 'CocoSharp';
    src: url('../fonts/CocoSharp-Bold.woff2') format('woff2'),
        url('../fonts/CocoSharp-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/Helvetica.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue7';
    src: url('../fonts/HelveticaNeueBd.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'gilroy1';
    src: url("../fonts/Gilroy-Thin.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-Thin.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-Thin.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-Thin.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-Thin.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy1I';
    src: url("../fonts/Gilroy-ThinItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-ThinItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-ThinItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-ThinItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-ThinItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy2';
    src: url("../fonts/Gilroy-UltraLight.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-UltraLight.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-UltraLight.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-UltraLight.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-UltraLight.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy2I';
    src: url("../fonts/Gilroy-UltraLightItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-UltraLightItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-UltraLightItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-UltraLightItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-UltraLightItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy3';
    src: url("../fonts/Gilroy-Light.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-Light.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-Light.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-Light.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy3I';
    src: url("../fonts/Gilroy-LightItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-LightItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-LightItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-LightItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-LightItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy4';
    src: url("../fonts/Gilroy-Regular.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-Regular.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-Regular.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-Regular.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy4I';
    src: url("../fonts/Gilroy-RegularItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-RegularItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-RegularItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-RegularItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-RegularItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy5';
    src: url("../fonts/Gilroy-Medium.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-Medium.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-Medium.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-Medium.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-Medium.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy5I';
    src: url("../fonts/Gilroy-MediumItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-MediumItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-MediumItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-MediumItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-MediumItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy6';
    src: url("../fonts/Gilroy-SemiBold.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-SemiBold.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-SemiBold.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-SemiBold.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-SemiBold.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy6I';
    src: url("../fonts/Gilroy-SemiBoldItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-SemiBoldItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-SemiBoldItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-SemiBoldItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy7';
    src: url("../fonts/Gilroy/Gilroy-Bold.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-Bold.woff2") format('woff2'),
    url("../fonts/Gilroy-Bold.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-Bold.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy7I';
    src: url("../fonts/Gilroy-BoldItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-BoldItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-BoldItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-BoldItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-BoldItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy8';
    src: url("../fonts/Gilroy-ExtraBold.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-ExtraBold.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-ExtraBold.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-ExtraBold.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-ExtraBold.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy8I';
    src: url("../fonts/Gilroy-ExtraBoldItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-ExtraBoldItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-ExtraBoldItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-ExtraBoldItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy9';
    src: url("../fonts/Gilroy-Black.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-Black.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-Black.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-Black.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-Black.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy9I';
    src: url("../fonts/Gilroy-BlackItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-BlackItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-BlackItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-BlackItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-BlackItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy10';
    src: url("../fonts/Gilroy-Heavy.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-Heavy.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-Heavy.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-Heavy.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-Heavy.ttf") format('truetype');
}

@font-face {
    font-family: 'gilroy10I';
    src: url("../fonts/Gilroy-HeavyItalic.ttf") format('truetype'),
    url("../fonts/Gilroy/Gilroy-HeavyItalic.eot") format('embedded-opentype'),
    url("../fonts/Gilroy/Gilroy-HeavyItalic.woff") format('woff'),
    url("../fonts/Gilroy/Gilroy-HeavyItalic.woff2") format('woff2'),
    url("../fonts/Gilroy/Gilroy-HeavyItalic.ttf") format('truetype');
}

@font-face {
    font-family: 'SF5';
    src: url("../fonts/SFUIDisplay-Medium.woff2") format('woff2'),
    url("../fonts/SFUIDisplay-Medium.woff") format('woff');
    font-weight: 200;
}

@font-face {
    font-family: 'SF7';
    src: url("../fonts/SFUIDisplay-Bold.woff2") format('woff2'),
    url("../fonts/SFUIDisplay-Bold.woff") format('woff');
    font-weight: 200;
}

@font-face {
  font-family: "Inter7";
  src: url("../fonts/Inter-Bold.woff2") format('woff2'),
  url("../fonts/Inter-Bold.woff") format('woff');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}
