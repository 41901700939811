.popupContent {
    background-color: var(--white);
    border-radius: var(--br-modal);
    box-shadow: var(--sh-modal);
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
    max-height: 96vh;
    max-height: calc(var(--vh, 1vh) * 96);

    @media (max-width: 1100px) {
        padding: 18px;
    }
}

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.loader {
    z-index: 9999;
    background-color: unset;
    box-shadow: none;
}

.loaderOverlay {
    z-index: 9999999;
}

.loginModal {
    background: linear-gradient(180deg, #050432 0%, #01002F 100%);
    width: min(1512px, 90%);
    height: 90%;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 37px 33px;
    text-align: center;
    box-sizing: border-box;

    @media (max-width: 1150px) and (min-width: 1101px) {
        padding: 37px 5px
    }

    @media (max-width: 1100px) {
        width: min(372px, 100%);
        grid-template-columns: 1fr;
        padding: 42px 26px 33px 26px;
        height: auto;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    @media (max-width: 374px) {
        padding: 37px 20px;
    }
}

.loginMain {
    margin: auto;
    width: 360px;

    @media (max-width: 1100px) {
        width: 320px;
    }
}

.loginHeader {
    display: flex;
    align-items: center;
    margin-bottom: 78px;

    @media (max-width: 1100px) or (max-height: 850px) {
        margin-bottom: 36px;
    }

    .vl {
        height: 24px;
        width: 1px;
        background: rgba(255, 255, 255, 0.56);
        border-radius: 4px;
        border: none;
        margin-left: 18px;
        margin-right: 26px;

        @media(max-width: 1100px) {
            height: 18px;
        }
    }

    .loginHeaderName {
        font-size: 18px;
        line-height: 96.8%;
        color: var(--white);

        @media(max-width: 1100px) {
            font-size: 16px;
        }
    }
}

.loginBody {
    width: 350px;
    margin: 0 auto;
    gap: 48px;
    display: flex;
    flex-direction: column;

    @media(max-width: 1100px) {
        width: 100%;
        gap: 24px;
    }
}

.loginBodyHead {
    display: flex;
    flex-direction: column;
    gap: 18px;

    h2 {
        margin: 0;
        font-size: 48px;
        line-height: 32px;
        font-family: "gilroy8", sans-serif;
        color: var(--white);
    }

    span {
        font-size: 14px;
        line-height: 17px;
        font-family: "gilroy8", sans-serif;
        color: var(--white);

        .blue {
            color: #00B2FF;
            cursor: pointer;
        }
    }
}

.loginFormContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;

    button {
        width: 100%;
        border-radius: 56px;
        height: 42px;
        font-size: 14px;
        line-height: 17px;
        font-family: "gilroy8", sans-serif;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 18px;
        gap: 16px;
        border: none;
        cursor: pointer;
    }

    .googleButton {
        background-color: var(--white);
        color: #31495D;
    }

    .facebookButton {
        background-color: #052979;
        color: var(--white);
    }
}

.loginBottomContainer {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.loginInfo {
    display: flex;
    gap: 18px;
    white-space: nowrap;
    align-items: center;

    hr {
        width: 100%;
        height: 2px;
        border: none;
        background-color: #D9D9D9;
        opacity: 0.45;
    }

    span {
        font-size: 14px;
        line-height: 17px;
        color: var(--white);
        font-family: "gilroy8", sans-serif;
    }
}

.loginInputContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.loginButton {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #00B2FF;
    border-radius: 56px;
    border: none;
    margin-top: 10px;
    cursor: pointer;

    span {
        font-size: 14px;
        line-height: 17px;
        font-family: "gilroy8", sans-serif;
        color: var(--white);
    }
}

.carousel {
    max-height: calc(100vh - 150px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 150px);
    background-color: var(--white);
    border-radius: 26px;
    max-width: 820px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1400px) {
        max-width: 720px;
    }

    @media (max-width: 1280px) {
        max-width: 620px;
    }
}

.loginSplide {
    z-index: 2;
    cursor: pointer;

    .carouserImage {
        box-shadow: 0 39px 23px -20px rgb(5 4 50 / 17%);
        border-image-source: linear-gradient(to left, #040166, #050432);
        border-image-slice: 1;
        border: double 7px transparent;
        border-radius: 13px;
        background-image: linear-gradient(white, white), radial-gradient(circle at top left, #040166, #050432);
        background-origin: border-box;
        background-clip: content-box, border-box;
        margin-bottom: 110px;

        @media (max-height: 1250px) {
            margin-bottom: 80px;
        }

        @media (max-height: 1000px) {
            margin-bottom: 60px;
        }

        @media (max-height: 850px) {
            margin-bottom: 40px;
        }
      }
}

.carouserBlock {
    margin-bottom: 26px;
}

.carouserHeader {
    font-size: 24px;
    line-height: 24px;
    font-family: "CocoSharp", sans-serif;
    color: #050432;
    margin: 22px 0 32px 0;
}

.carouserText {
    max-width: 374px;
    margin: 0 auto;
    display: block;
    font-size: 18px;
    line-height: 22px;
    font-family: "CocoSharp", sans-serif;
    color: #050432;
}

//Access
.accessModal {
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(20, 67, 114, 0.08);
    border-radius: 15px;
    padding: 30px;
    max-height: 90%;
    //width: min(90%, 697px);
    max-width: 90%;
}

.accessContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.accessHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            cursor: pointer;
        }
    }

    h3 {
        font-size: 22px;
        line-height: 27px;
        font-family: "gilroy7", sans-serif;
        margin: 0;
    }

    span {
        font-size: 15px;
        line-height: 17px;
        color: var(--main-gray);
    }
}

.accessCardsContainer {
    background: rgba(255, 255, 255, 0.5);
    //backdrop-filter: blur(17.5px);
    border-radius: 26px;
    padding: 14px 22px;
    display: flex;
    gap: 36px;
    font-family: "SF5", sans-serif;

    @media (max-width: 800px) {
        flex-wrap: wrap;
        overflow-y: auto;
        height: calc(100vh - 254px);
        justify-content: center;

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

.accessCard {
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(18, 62, 106, 0.15);
    border-radius: 26px;
    padding: 24px;
    width: 280px;
    box-sizing: border-box;

    h4 {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 15px;
        margin-top: 0;
        font-family: "SF7", sans-serif;
    }

    .accessCardText {
        font-size: 15px;
        line-height: 130%;
        color: #848199;
    }
}

.accessCardRight {
    color: #231D4F;
}

.accessCardLeft {
    color: var(--bg-light-green);
}

.accessPricesBlock {
    display: flex;
    font-size: 15px;
    line-height: 18px;
    position: relative;
    margin-bottom: 12px;
    align-items: baseline;
    gap: 6px;

    img {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: -4px;
    }
}

.accessFirstPrice {
    opacity: 0.4;
    font-size: 24px;
    line-height: 46px;
    font-family: "SF7", sans-serif;
}

.accessSecondPrice {
    font-size: 24px;
    line-height: 46px;
    font-family: "SF7", sans-serif;
}

.accessPriceOnDate {
    color: var(--main-gray);
}

.accessBenefitsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 36px;
}

.accessBenefit {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #848199;
}

.accessButton {
    border-radius: 24px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    color: var(--white);
    font-size: 15px;
    line-height: 18px;
}

.accessBlueButton {
    background-color: #231D4F;
}

.accessGreenButton {
    background-color: var(--bg-light-green);
}

//locking
.lockingOverlay {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.87) 76.56%);
    backdrop-filter: blur(2.5px);
    height: calc(100vh - 174px);
    left: unset;
    top: unset;
    background-color: unset !important;
    z-index: 9;

    @media (max-width: 1100px) {
        height: calc(100vh - 157px);
    }
}

.smallOverlay {
    width: calc(100vw - 422px);

    @media (max-width: 1100px) {
        width: 100vw;
    }
}

.bigOverlay {
    width: calc(100vw - 108px);

    @media (max-width: 1100px) {
        width: 100vw;
    }
}

.lockingContent {
    background: unset;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
    gap: 100px;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
}

.lockingDataContainer {
    display: flex;
    gap: 12px;
    border-radius: 24px;
    background-image: url("../../assets/images/lockBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 36px;
    box-sizing: border-box;
    color: var(--white);

    .lockingImage {
        max-height: 300px;
        max-width: calc(100% - 480px);

        @media (max-width: 1300px) {
            max-width: calc(100% - 340px);
        }
    }
}

.lockingData {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
        font-size: 22px;
        line-height: 27px;
        font-family: "gilroy7", sans-serif;
        margin: 0;
    }

    span {
        font-size: 15px;
        line-height: 130%;
        font-family: "gilroy5", sans-serif;
    }
}

.lockingBenefitsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.lockingBenefit {
    display: flex;
    gap: 10px;
    align-items: center;
}

.lockingButtonContainer {
    display: flex;
    gap: 16px;

    @media (max-width: 1300px) {
        flex-direction: column;
    }

    button, a {
        height: 40px;
        border-radius: 24px;
        font-size: 15px;
        line-height: 18px;
        font-family: "SF5", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
    }

    .lockingPlansButton {
        color: #231D4F;
        width: 206px;
        background-color: var(--white);
        border: none;

        @media (max-width: 1300px) {
            width: 100%;
        }
    }

    .lockingCheckButton {
        color: var(--white);
        display: flex;
        gap: 8px;
        width: 258px;
        background: inherit;
        border: 1px solid var(--white);
        filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.25));

        @media (max-width: 1300px) {
            width: 100%;
        }
    }
}

.intServError {
    display: flex;
    flex-direction: column;
    gap: 48px;

    div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;

        h2 {
            margin: 0;
            font-family: "gilroy7", sans-serif;
            font-size: 28px;
            line-height: 34px;
            color: black;
        }

        span {
            font-size: 18px;
            line-height: 21px;
            color: #232323;
        }
    }

    button {
        padding: 12px 19px;
        border-radius: 12px;
        box-shadow: 0px 8px 25px 0px #00000040;
        background: #232323;
        font-family: "HelveticaNeue7", sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
    }
}